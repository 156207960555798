import React, { memo } from 'react';
import memoize from 'memoize-one';

import { FixedSizeList as List, areEqual } from 'react-window';
import { Checkbox } from '@material-ui/core';

const createRayoData = memoize((items, handleCheck) => ({
    items,
    handleCheck,
}));

function ListaRayosAsignar({ rayos, width, filtro_rayos, handleCheck, vista }) {
    let filterRayos = rayos.filter((rayo) => {
        let bandera = false;

        if (
            rayo.name_rayo.toLowerCase().includes(filtro_rayos.toLowerCase()) ||
            rayo.dni_rayo.toLowerCase().includes(filtro_rayos.toLowerCase()) 
        ) {
            bandera = true;
        }
        return bandera;
    });
    const itemData = createRayoData(filterRayos, handleCheck);
    let height = window.innerHeight - 64 - 38 - 54;
    if (vista) height = height - 47;

    return (
        <List itemCount={filterRayos.length} itemData={itemData} height={height} width={width} itemSize={65}>
            {ItemRayo}
        </List>
    );
}

const ItemRayo = memo(({ data, index, style }) => {
    const { items, handleCheck } = data;
    const item = items[index];

    return (
        <div
            className="w-100 m-0"
            style={{ top: style.top, position: style.position, paddingTop: 15, paddingLeft: 15, paddingRight: 15 }}
        >
            <div className="row m-0 w-100">
                <div className="d-inline-block p-relative" style={{ width: 65 }}>
                    <img src={item.image_rayo} height="50" width="50" alt="rayo" className="img-rayo" />
                    <div
                        className={
                            item.status_rayo === 1 ? 'position-absolute dot activo' : 'position-absolute dot inactivo'
                        }
                        style={{ top: 44, left: 45 }}
                    ></div>
                </div>
                <div className="d-inline-flex align-items-center" style={{ width: 'calc(100% - 45px - 65px)' }}>
                    <div className="w-100">
                        <p className="text-exo-bold mb-0 txt-rayo-name color-dark-grey">{item.name_rayo}</p>
                        <p className="text-roboto-regular mb-0 txt-rayo-name color-dark-grey">{'+ ' + item.number_rayo}</p>
                        <p className="text-roboto-regular mb-0 txt-rayo-name color-dark-grey">
                            {item.providers.length !== 0 ? item.providers[0].name_provider : false}
                        </p>
                    </div>
                </div>
                <div className="d-inline-flex align-items-center justify-content-center" style={{ width: 45 }}>
                    <Checkbox
                        value="checkedA"
                        color="secondary"
                        checked={item.selected}
                        onChange={(e) => handleCheck(e, item._id)}
                    />
                </div>
            </div>
        </div>
    );
}, areEqual);

export default ListaRayosAsignar;
