import React, { useState, useEffect } from "react";
import url from "../../config";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import ActiveDot from "../../assets/components/mantenedores/ActiveDot";
import ContainModal from "../../assets/components/mantenedores/ContainModal";

const columns = [
  { id: "image_type_activity", label: "Actividades", align: "left" },
  { id: "status_type_activity", label: "Estado", align: "left" },
  { id: "accion", label: "Acción", align: "right" },
];
const ImageText = ({ image, text }) => {
  return (
    <Box display="inline-flex" paddingTop="4px">
      <Box height={20} display="flex" alignItems="center" marginRight={2}>
        <img src={image} height="20" width="20" alt="icono tipo de actividad" />
      </Box>
      <Box height={20} display="flex" alignItems="center">
        {text}
      </Box>
    </Box>
  );
};

const TipoActividades = () => {
  const [typeActivities, setTypeActivities] = useState(null);
  const [typeActivity, setTypeActivity] = useState(null);
  const [modal, setModal] = useState(false);
  // const [typeModal, setTypeModal] = useState(1); // 1: Crear || 2: Editar
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    getTypeActivities();
  }, []);

  const getTypeActivities = () => {
    url.api.get("/Type-Activities/type_activities/").then((response) => {
      setTypeActivities(response.data);
    });
  };

  const getTypeActivity = (id) => {
    url.api.get("/Type-Activities/type_activity/" + id).then((response) => {
      setTypeActivity(response.data);
      setModal(true);
    });
  };

  const closeModal = (status) => {
    setModal(false);
    setTypeActivity(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box pt={4}>
      <Container>
        <Typography variant="h1" gutterBottom>
          Tipo de actividades
        </Typography>

        <Box width="100%" display="flex" mb={3}>
          <Box flexGrow="1">
            <TextField
              size="small"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              variant="outlined"
              placeholder="Buscar..."
              style={{ minWidth: 250 }}
            />
          </Box>
          <Button color="secondary" variant="contained">
            Crear nuevo
          </Button>
        </Box>

        <TableContainer className="table-white">
          <Table aria-label="Type activities">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell align={column.align} key={index}>
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {typeActivities
                ? typeActivities
                    .filter((activity) => {
                      let bandera = false;
                      if (
                        activity.name_type_activity
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        activity._id
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      ) {
                        bandera = true;
                      }
                      return bandera;
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow hover key={i}>
                        <TableCell>
                          <ImageText
                            image={row.image_type_activity}
                            text={row.name_type_activity}
                          />
                        </TableCell>
                        <TableCell>
                          <ActiveDot status={row.status_type_activity} />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="update"
                            onClick={() => {
                              
                              getTypeActivity(row._id)}}
                          >
                            <CreateIcon color="primary" />
                          </IconButton>
                          <IconButton aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                : false}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100, { value: -1, label: "Todas" }]}
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count, page }) => {
            return from + " - " + to + " de " + count + " tipos de actividades";
          }}
          component="div"
          count={typeActivities ? typeActivities.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>

      <ContainModal setModal={closeModal} modal={modal}>
        {typeActivity ? (
          <Paper elevation={3} className="md-modal bg-dark">
            <Box p={3} width="100%">
              <Typography variant="h4" className="text-white">Modificar tipo de actividad</Typography>
              
            </Box>
          </Paper>
        ) : (
          <Box></Box>
        )}
      </ContainModal>
    </Box>
  );
};
export default TipoActividades;
