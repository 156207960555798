import { OBTENER_CLIENTES, ERROR_CLIENTES, RES_ERROR } from '../actions/Clientes';

const estadoInicial = {
    clientes: null,
    error: null,
};

const INITIAL_STATE = {
    loggedIn: true,
};

const estadosReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case OBTENER_CLIENTES:
            return {
                ...estado,
                clientes: accion.clientes,
            };
        case ERROR_CLIENTES:
            return {
                ...estado,
                error: accion.error,
            };
        case RES_ERROR:
            return INITIAL_STATE;
        default:
            return estado;
    }
};

export default estadosReducer;