import { OBTENER_ESTADOS, ERROR_ESTADOS, RES_ERROR } from '../actions/Estados';

const estadoInicial = {
    estados: null,
    error: null,
};

const INITIAL_STATE = {
    loggedIn: true,
};

const estadosReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case OBTENER_ESTADOS:
            return {
                ...estado,
                estados: accion.estados,
            };
        case ERROR_ESTADOS:
            return {
                ...estado,
                error: accion.error,
            };
        case RES_ERROR:
            return INITIAL_STATE;
        default:
            return estado;
    }
};

export default estadosReducer;