import url from '../../config';
import axios from 'axios';

export const ERROR_TIPO_VEHICULO = 'ERROR_TIPO_VEHICULO';
export const RES_ERROR = "RES_ERROR";
export const error = () => {
  return async (dispatch) => {
    dispatch({ type: RES_ERROR });
  };
};

export const OBTENER_TIPO_VEHICULOS = 'OBTENER_TIPO_VEHICULOS';
export const obtenerTipoVehiculos = () => {
    return async (dispatch) => {
        try {
            await axios({
                method: 'get',
                url: url.host + '/Type-Vehicle/type_vehicles/',
                headers: [],
            }).then((response) => {
                switch (response.status) {
                    case 200:
                        dispatch({
                            type: OBTENER_TIPO_VEHICULOS,
                            tipoVehiculos: response.data,
                        });
                        break;

                    default:
                        dispatch({
                            type: ERROR_TIPO_VEHICULO,
                            error: 'Error ' + response.status + ' al realizar la petición obtener tipos de vehículos',
                        });
                        break;
                }
            }).catch((e)=>{
                dispatch({
                    type: ERROR_TIPO_VEHICULO,
                    error: 'Error al realizar la petición obtener tipos de vehículos',
                });
            })
        } catch (e) {
            dispatch({
                type: ERROR_TIPO_VEHICULO,
                error: 'Error al realizar la petición obtener tipos de vehículos',
            });
        }
    };
};