import React from "react";
import $ from "jquery";

import Button from "@material-ui/core/Button";

class EliminarTareas extends React.Component {
  render() {
    return (
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-white">
          <div className="modal-body">
            <h4 className="h4-modal text-center">
              {this.props.vista === "mapa"
                ? "¿Está seguro que desea eliminar este Grupo de Tareas?"
                : this.props.vista === "lista"
                ? "¿Está seguro que desea eliminar esta Tarea?"
                : "¿Está seguro que desea eliminar estas Tareas?"}
            </h4>
            <h4 className="text-roboto-regular color-mega-dark-grey text-center">
              {this.props.vista === "mapa"
                ? "ID Grupo de Tareas"
                : this.props.vista === "lista"
                ? "ID de Tarea"
                : "ID de Tareas"}
            </h4>

            <div className="w-100 back-grey-input br-4 p-2 mb-2">
              <h6 className="w-100 text-roboto-regular text-center color-mega-dark mb-0">
                {typeof this.props.code === undefined ||
                this.props.code === null
                  ? ""
                  : this.props.code.map((code, i) => {
                      let codigo = code.codigo;
                      if (i !== 0) {
                        codigo = ", " + codigo;
                      }
                      return codigo;
                    })}
              </h6>
            </div>
          </div>
          <div className="modal-footer row m-0">
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="button"
                variant="text"
                color="primary"
                onClick={() => {
                  $("#modalAccionMasiva").modal("hide");
                  $("#modal").modal("hide");
                }}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                onClick={() =>
                  this.props.delete(
                    this.props.data === null ? "" : this.props.data._id
                  )
                }
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EliminarTareas;
