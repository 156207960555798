import React from "react";
import Mapa from "../mapas/Mapa";

import IconoLupa from "../../icons/icono-lupa-white.svg";
import IconoRayo from "../../icons/icono-rayo-white.svg";

import Button from "@material-ui/core/Button";
import Cargando from "../cargando/Cargando";
import ListaRayosAsignar from "../lists/ListaRayosAsignar";

class AsignarRayo extends React.Component {
  // vista: true => backlog tasks
  // vista: false => asignar gt
  traerMapa = () => {
    let localidades = [];

    const icon = {
      url: "https://storage.googleapis.com/poc-rayo-fm.appspot.com/icono_mapaRayo.png",
      scaledSize: new window.google.maps.Size(23, 30),
      origin: new window.google.maps.Point(0, 0),
    };

    this.props.wayPoints.forEach((element) => {
      localidades.push({
        icon: icon,
        position: element.location,
      });
    });

    if (localidades === null || localidades === undefined) {
      return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <div className="loader"></div>
        </div>
      );
    } else {
      return (
        <Mapa
          id="myMap"
          options={{
            center: { lat: -33.4724228, lng: -70.7699137 },
            zoom: 10,
          }}
          asignar={true}
          detalle={false}
          localizaciones={[]}
          rayos={null}
        />
      );
    }
  };

  render() {
    return (
      <div className="row m-0 home-screen">
        {this.props.vista ? (
          <div className="col-md-9 p-0 home-screen">{this.traerMapa()}</div>
        ) : (
          false
        )}
        <div className="col-md-3 p-0 home-screen plomo">
          <div className="row m-0">
            <div className="input-group">
              <input
                className="form-control input-buscar"
                placeholder="Rayos"
                name="inputBuscarRayo"
                onChange={(e) => this.props.handleChange(e)}
                value={this.props.inputBuscarRayo}
              />
              <div className="input-group-append append-crear m-0 d-flex justify-content-center align-items-center">
                <img src={IconoLupa} height="18" alt="lupa" />
              </div>
            </div>
            <div className="paper-middle w-100">
              {this.props.vista ? (
                <div
                  className="w-100"
                  style={{ paddingTop: 15, paddingLeft: 15, paddingRight: 15 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-capitalize py-1 w-100"
                    startIcon={
                      <img height="20" src={IconoRayo} alt="Icono tarea" />
                    }
                    onClick={() => this.props.unassignTasksGroup()}
                  >
                    Des-asignar Rayo
                  </Button>
                </div>
              ) : (
                false
              )}

              <ListaRayosAsignar
                rayos={this.props.rayos}
                width={"100%"}
                filtro_rayos={this.props.inputBuscarRayo}
                handleCheck={this.props.handleCheck}
                vista={this.props.vista}
              />
            </div>
          </div>

          <div className="bottom-fix">
            <div className="row m-0">
              <div className="col-6 pr-2">
                <Button
                  variant="contained"
                  color="primary"
                  className="text-capitalize py-1 w-100"
                  startIcon={
                    <img height="20" src={IconoRayo} alt="Icono tarea" />
                  }
                  onClick={() => this.props.modifyTaskGroups()}
                >
                  Asignar Rayo
                </Button>
              </div>
              <div className="col-6 pl-2 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-secondary w-100"
                  onClick={() => this.props.handleAsignarRayo([])}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.props.vista ? (
          false
        ) : (
          <div className="col-md-9 p-0 home-screen">{this.traerMapa()}</div>
        )}
        <Cargando cargando={this.props.cargando} />
      </div>
    );
  }
}

export default AsignarRayo;
