import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const CargandoMapa = () => {
    return (
        <div
            style={{
                width: '100%',
                minHeight: '100%',
                position: 'absolute',
                backgroundColor: '#000000',
            }}
            className="d-flex justify-content-center align-items-center"
        >
            <CircularProgress />
        </div>
    );
};

export default CargandoMapa;