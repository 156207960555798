export const SELECCIONADOS = 'SELECCIONADOS';

export const ingresarSeleccionados = (seleccionados) => {
    return async (dispatch) => {
        dispatch({
            type: SELECCIONADOS,
            seleccionados: seleccionados,
        });
    };
};
