import React from 'react';
import './Cargando.css';
import CircularProgress from '@material-ui/core/CircularProgress';

class Cargando extends React.PureComponent {
    render() {
        if (this.props.cargando) {
            return (
                <div className="container-cargando">
                    <div className="container-gif">
                        <CircularProgress />
                    </div>
                </div>
            );
        } else {
            return <div className="container-cargando oculto"></div>;
        }
    }
}

export default Cargando;
