import {
    OBTENER_WEBHOOKS_TAREAS,
    OBTENER_WEBHOOKS_GRUPOS_TAREAS,
    ERROR_WEBHOOKS,
    RES_ERROR,
} from '../actions/Webhooks';

const estadoInicial = {
    webhooksTareas: null,
    webhooksGruposTareas: null,
    error: null,
};

const INITIAL_STATE = {
    loggedIn: true,
};

const webhooksReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case OBTENER_WEBHOOKS_TAREAS:
            return {
                ...estado,
                webhooksTareas: accion.webhooksTareas,
            };
        case OBTENER_WEBHOOKS_GRUPOS_TAREAS:
            return {
                ...estado,
                webhooksGruposTareas: accion.webhooksGruposTareas,
            };
        case ERROR_WEBHOOKS:
            return {
                ...estado,
                error: accion.error,
            };
        case RES_ERROR:
            return INITIAL_STATE;
        default:
            return estado;
    }
};

export default webhooksReducer;
