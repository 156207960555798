import { OBTENER_TIPO_VEHICULOS, ERROR_TIPO_VEHICULO, RES_ERROR } from '../actions/Tipo_Vehiculos';

const estadoInicial = {
    tipoVehiculos: null,
    error: null,
};

const INITIAL_STATE = {
    loggedIn: true,
};

const tipoVehiculosReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case OBTENER_TIPO_VEHICULOS:
            return {
                ...estado,
                tipoVehiculos: accion.tipoVehiculos,
            };
        case ERROR_TIPO_VEHICULO:
            return {
                ...estado,
                error: accion.error,
            };
        case RES_ERROR:
            return INITIAL_STATE;
        default:
            return estado;
    }
};

export default tipoVehiculosReducer;