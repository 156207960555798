import { SELECCIONADOS } from '../actions/SeleccionMapa';

const estadoInicial = {
    seleccionados: [],
    error: null,
};

const seleccionReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case SELECCIONADOS:
            return {
                ...estado,
                seleccionados: accion.seleccionados,
            }
        default:
            return estado;
    }
};

export default seleccionReducer;