import { GUARDAR_TIEMPO, GUARDAR_RAYO } from '../actions/Tracking';

const estadoInicial = {
    tiempo: null,
    error: null,
    rayo: null,
};

const trackingReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case GUARDAR_TIEMPO:
            return {
                ...estado,
                tiempo: accion.tiempo,
            };
        case GUARDAR_RAYO:
            return {
                ...estado,
                rayo: accion.rayo,
            };
        default:
            return estado;
    }
};

export default trackingReducer;
