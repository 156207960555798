import React from "react";
import Button from "@material-ui/core/Button";

class DuplicarTareas extends React.Component {
  render() {
    return (
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-white">
          <div className="modal-body">
            <h4 className="h4-modal text-center">Duplicar</h4>
            <h4 className="text-roboto-regular color-mega-dark-grey text-center">
              {this.props.vista === "mapa"
                ? "ID Grupo de Tareas"
                : "ID de Tarea"}
            </h4>
            {this.props.vista === "mapa" ? (
              <input
                defaultValue={
                  this.props.data === null ? "" : this.props.data.code_taskgroup
                }
                disabled
                className="form-control input-id-grupo mb-3"
              />
            ) : (
              <input
                defaultValue={
                  this.props.data === null ? "" : this.props.data.code_task
                }
                disabled
                className="form-control input-id-grupo mb-3"
              />
            )}

            {this.props.vista === "mapa" ? (
              false
            ) : (
              <>
                <label className="text-roboto-regular color-mega-dark-grey mb-1 mt-2">
                  Ingrese motivo
                </label>
                <input
                  placeholder="Motivo..."
                  className="form-control txt-modal"
                  name="motivoDuplicar"
                  value={this.props.motivoDuplicar}
                  onChange={this.props.handleChange}
                  maxLength={80}
                />
                <label
                  className="link-style mt-1 cursor-pointer"
                  onClick={() => {
                    this.props.handleState("motivoDuplicar", "Segúnda visita");
                  }}
                >
                  Segúnda visita
                </label>
              </>
            )}
          </div>
          <div className="modal-footer row m-0">
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="button"
                variant="text"
                color="primary"
                onClick={() => this.props.cerrarModal(this.props.nameModal)}
              >
                Cancelar
              </Button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={this.props.duplicate}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DuplicarTareas;
