import { OBTENER_TIPO_ACTIVIDADES, ERROR_TIPO_ACTIVIDADES, RES_ERROR } from '../actions/Tipo_Actividades';

const estadoInicial = {
    tipoActividades: null,
    error: null,
};

const INITIAL_STATE = {
    loggedIn: true,
};

const tipoActividadesReducer = (estado = estadoInicial, accion) => {
    switch (accion.type) {
        case OBTENER_TIPO_ACTIVIDADES:
            return {
                ...estado,
                tipoActividades: accion.tipoActividades,
            };
        case ERROR_TIPO_ACTIVIDADES:
            return {
                ...estado,
                error: accion.error,
            };
        case RES_ERROR:
            return INITIAL_STATE;
        default:
            return estado;
    }
};

export default tipoActividadesReducer;